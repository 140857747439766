import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SessionState {
    session: Session | null;
    sessions: Sessions | null;
    sessionOptions: Sessions | null;
}

export const session = merge(getBaseStore('session', 'assist/'), {
    actions: {
        async createSession({ commit, state }: ActionContext<SessionState, RootState>) {
            commit('updateSession', {
                user_phone_number: '',
                user_email: '',
            });

            return state.session;
        },
    },
});
